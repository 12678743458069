<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M6.5,600.1c1.1-275,195.4-515.9,464.9-571.6C672.5-13,852,34.2,1004.9,172c105.6,95.2,167.9,215.6,184.1,356.9  c20.8,180.8-31.5,339.5-156.5,472.3c-95,101-212.7,161.4-350,179c-188.6,24.1-354.7-28.2-493.9-158.8  C87.9,926.8,29.8,809.2,11.7,672C8.6,648.3,8.2,624.1,6.5,600.1z M67.1,631.2C68.7,744.5,119.7,869,221.7,971  c92.4,92.3,204.9,144.4,335.5,154.1c163.9,12.2,306.4-39,422.4-154.9C1095.3,854.7,1146,713,1131.2,549.6  c-11.4-125.9-63.1-234.3-153.7-321.8C843.8,98.9,683.2,50.8,500.2,82.8C251.4,126.2,67.1,347.3,67.1,631.2z"
    />
    <path
      fill="currentColor"
      d="M840.2,382.3c-35.1-0.2-63.8,28.1-64,63.2c0,0.3,0,0.5,0,0.8v136.9h-12.6V306.7  c0.2-35.1-28.1-63.8-63.2-64c-0.3,0-0.5,0-0.8,0c-34.2,0.7-61.5,28.5-61.5,62.7v277.9h-12.6V245.5c0.2-35.1-28.1-63.8-63.2-64  c-0.3,0-0.5,0-0.8,0c-34.2,0.7-61.6,28.6-61.5,62.8v339h-12.6v-275c0.2-35.1-28.1-63.8-63.2-64c-0.3,0-0.5,0-0.8,0  c-34.2,0.7-61.6,28.6-61.5,62.8v370.4l-49.7-68.3c-20.4-28-59.7-34.2-87.7-13.8c-28,20.4-34.2,59.7-13.8,87.7l0,0l197.2,271.2  c14.2,19.4,36.8,30.9,60.9,30.9h310.1c35,0,65.3-24.1,73.3-58.1l41.6-176.8c5.3-22.6,8-45.8,8-69v-236  C901.8,410.9,874.4,383,840.2,382.3z"
    />
  </svg>
</template>
